const ErrorWidget = (props) => {
    if(props.error) {
        return <div>

        </div>
    } else {
        return <></>
    }

}

export default ErrorWidget